<template lang="pug">
  .impersonate-button(@click="stopImpersonating") {{ $t("header.stop_impersonating") }}
</template>

<script>
  // misc
  import { api } from "@/config"

  export default {
    methods: {
      async stopImpersonating() {
        await api.post("/maestro/users/stop_impersonating", {}, { skipErrorToast: true })
        window.location.replace("/")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .impersonate-button
    cursor: pointer
    background: $default-red
    color: $default-white
    padding: 3px 10px
    border-radius: 3px
    transition: all 0.1s linear

    &:hover
      background: darken($default-red, 10)
</style>
